import Tooltip from "@material-ui/core/Tooltip";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Skeleton from "@material-ui/lab/Skeleton";
import { getCentralInfo } from "@utils/apiServices/central";
import clsx from "clsx";
import map from "lodash/map";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";

import * as B from "./Banner.module.scss";
import {
  bannerDataSetting,
  fetchPublishedBanner,
  handleBannerLink,
  isValidLink,
} from "./utils";

const Banner = () => {
  const [banners, setBanners] = useState([]);
  const [interval, setInterval] = useState(3500);
  const [autoPlay, setAutoPlay] = useState(true);

  const getSliderSetting = async () => {
    try {
      const { error, data: bannerData } = await getCentralInfo({
        isPublic: true,
        keys: ["bannerTransitionSpeed", "bannerAutoPlay"],
      });
      if (error) {
        throw error;
      }
      if (bannerData) {
        bannerDataSetting({
          bannerData,
          setInterval,
          setAutoPlay,
        });
      }
    } catch (error) {
      console.error("getSliderSetting", error);
    }
  };

  useEffect(() => {
    fetchPublishedBanner(setBanners);
    getSliderSetting();
  }, []);

  const renderBanners = () => {
    const item = [];
    map(banners, (banner, index) => {
      const { largeUrl } = banner.imageLink?.wide || {};
      item.push(
        <div
          className={clsx(
            isValidLink(banner.targetUrl) ? "pointer" : "inherit",
            "bg-white h-[300px] flex justify-center flex-col bg-center bg-cover relative",
          )}
          key={index}
          onClick={() => handleBannerLink(banner.targetUrl)}>
          {largeUrl && (
            <Image
              src={largeUrl}
              className="object-cover"
              alt="banner"
              sizes="(max-width: 1350px) 50vw, 100vw"
              fill
              priority
            />
          )}
          <div className={B.textContent}>
            <div className={B.textContentInner}>
              <Tooltip title={banner.title} placement="bottom-start">
                <div className={B.banner_title}>{banner.title}</div>
              </Tooltip>
              <Tooltip title={banner.description} placement="bottom-start">
                <div className={B.banner_desc}>{banner.description}</div>
              </Tooltip>
            </div>
          </div>
          <div className={B.banner_title_bg} />
        </div>,
      );
    });
    return item;
  };

  return (
    <div className={B.banner_wrapper} test-id="banner">
      {banners?.length > 0 ? (
        <Carousel
          className={B.banner_custom}
          interval={interval}
          showThumbs={false}
          showStatus={false}
          autoPlay={autoPlay}
          infiniteLoop={true}
          renderArrowPrev={(onClickHandler, hasPrev) =>
            hasPrev && (
              <div
                test-id="bannerLeftBtn"
                className={`${B.banner_arrow} ${B.banner_arrow_left}`}
                onClick={onClickHandler}>
                <ChevronLeftIcon />
              </div>
            )
          }
          renderArrowNext={(onClickHandler, hasNext) =>
            hasNext && (
              <div
                test-id="carouselArrow"
                className={`${B.banner_arrow} ${B.banner_arrow_right}`}
                onClick={onClickHandler}>
                <ChevronRightIcon />
              </div>
            )
          }>
          {renderBanners()}
        </Carousel>
      ) : (
        <Skeleton variant="rect" width="100%" height={300} />
      )}
    </div>
  );
};

export default Banner;

import { getPublishedBanner } from "@utils/apiServices/banner";

export const isValidLink = (link) => {
  const re = /^(http|https):\/\//i;
  return re.test(link);
};

export const fetchPublishedBanner = async (
  setBanners = () => {
    // This is intentional
  },
) => {
  try {
    const { data, error } = await getPublishedBanner();
    if (error) {
      throw error;
    }
    if (data) {
      setBanners(data);
    }
  } catch (error) {
    console.error("fetchPublishedBanner", error);
  }
};

export const bannerDataSetting = ({ bannerData, setAutoPlay, setInterval }) => {
  bannerData.forEach((item) => {
    if (item.key === "bannerAutoPlay") {
      if (item.value === "true") {
        return setAutoPlay(true);
      }
      return setAutoPlay(false);
    }
    if (item.key === "bannerTransitionSpeed") {
      return setInterval(Number(item.value));
    }
  });
};

export const handleBannerLink = (link) => {
  const match = isValidLink(link);
  if (match) {
    window.open(link, "_blank");
  }
};

import filter from "lodash/filter";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import groupBy from "lodash/groupBy";

import { sortBySerialNumber } from "./object-utils";

export const categoryI18nInfo = (item, language = "en") => {
  const { name = "", description = "", i18n = {} } = item ?? {};
  if (language === "en") {
    return {
      name,
      description,
    };
  }
  if (i18n !== null && i18n[language]) {
    return {
      name: i18n[language].name,
      description: i18n[language].description,
    };
  }
  return {
    name,
    description,
  };
};

export const categoryIdToi18nName = ({ id, categoryDict, language }) => {
  const matchedCategory = find(categoryDict, (item) => item.categoryId === id);
  return categoryI18nInfo(matchedCategory, language).name;
};

export const categoryIdsToi18nName = ({
  categoryIds,
  categoryDict,
  language,
  trim = false,
}) => {
  const names = categoryIds.map((id) => {
    return categoryIdToi18nName({ id, categoryDict, language });
  });

  if (trim && names.length > 3) {
    if (names.length === 4) {
      names[1] = "...";
    }
    if (names.length === 5) {
      names[1] = "...";
      names[2] = "...";
      names.splice(1, 1);
    }
  }

  return names;
};

export const groupCategory = (data) => {
  if (!data) {
    return [];
  }
  const groupByParentId = (groupData) =>
    groupBy(groupData, (item) => item.parentId);
  const flattedObjectToArray = (groupData) =>
    groupData ? [...Object.values(groupData)] : [];
  const parentAddRelatedChildren = (child, parent) => {
    const parentObject = groupBy(
      sortBySerialNumber(parent),
      (item) => item.id || item.categoryId,
    ); // array to object
    Object.keys(child).forEach((key) => {
      if (parentObject[key]) {
        // sort Children
        const sortedChildren = sortBySerialNumber(child[key]);
        parentObject[key][0].children = sortedChildren; // groupBy categoryId array length must be 1
      } else {
        throw new Error(
          `parentId: ${key} not found in depth ${parentObject[key][0].depth}`,
        );
      }
    });
    return parentObject;
  };
  const groupDataByDepth = groupBy(data, (item) => item.depth);
  const maxDepth = parseInt(
    Object.keys(groupDataByDepth).reduce(
      (prev, current) => (prev < current ? current : prev),
      0,
    ),
    10,
  );

  for (let index = maxDepth; index > 0; index -= 1) {
    if (index >= 1)
      parentAddRelatedChildren(
        groupByParentId(groupDataByDepth[index]),
        groupDataByDepth[index - 1],
      );
  }
  const flattedDepth1 = sortBySerialNumber(
    flattedObjectToArray(groupDataByDepth[0]),
  );
  return flattedDepth1;
};

export const categoryIdToCategoryItem = ({ id, categoryDict }) => {
  const matchedCategory = find(categoryDict, (item) => item.categoryId === id);
  return matchedCategory;
};

export const categoryIdToCategoryItemIndex = ({
  id,
  parentId = null,
  categoryDict,
}) => {
  const matchedCategory = findIndex(
    filter(categoryDict, ["parentId", parentId]),
    (item) => item.categoryId === id,
  );
  return matchedCategory;
};

export const categoryIdsToCategoryItemIndex = ({
  categoryIds,
  categoryDict,
}) => {
  const result = [];
  for (let index = 0; index < categoryIds.length; index += 1) {
    const id = categoryIds[index];
    const parentId = index > 0 ? categoryIds[index - 1] : null;
    result.push(categoryIdToCategoryItemIndex({ id, parentId, categoryDict }));
  }
  return result;
};
export const getCategoryParentList = ({ categoryList, currentId, depth }) => {
  let tempId = currentId;
  const temp = [currentId];
  for (let i = 0; i < depth; i += 1) {
    // eslint-disable-next-line no-loop-func
    const result = categoryList.filter((category) => category.id === tempId);
    if (result.length > 0) {
      temp.push(result[0].parentId);
      tempId = result[0].parentId;
    }
  }
  return temp;
};

export const getCategoryParentNameList = ({
  categoryList,
  currentId,
  depth,
  language = "en",
}) => {
  const parentIds = getCategoryParentList({ categoryList, currentId, depth });
  const nameList = parentIds.map((id) => {
    const result = categoryList.filter((category) => category.id === id);
    return categoryI18nInfo(result[0], language).name;
  });
  return nameList.reverse();
};

export const getFullTooltipSlug = (nameSlugs) => {
  return nameSlugs.reduce((acc, item, index) => {
    let accValue = acc;
    if (index < nameSlugs.length - 1) {
      accValue += `${item} / `;
    } else {
      accValue += item;
    }
    return accValue;
  }, "");
};

import { appRootPath, scm } from "@components/AppEnv";
import Banner from "@components/Banner";
import { Provider, useAppContext } from "@components/context";
import ExploreCustomLogin from "@components/ExploreCustomLogin";
import ExploreSection from "@components/ExploreSection";
import SearchInput from "@components/SearchInput";
import AutoCompleteList from "@components/SearchInput/AutoCompleteList";
import SuggestionList from "@components/SearchInput/SuggestionList";
import Categories from "@page-components/Categories";
import Footer from "@page-components/Footer";
import Header from "@page-components/Header";
import SettingLanguageTip from "@page-components/SettingLanguageTip";
import * as S from "@pages/explore.module.scss";
import { courseHandleSearch } from "@pages/search";
import { generatePageProps, pageAccess } from "@router/utils";
import {
  deleteSearchHistory,
  getAutoCompleteList,
  getCampaignList,
  getSearchHistory,
  getTrendingList,
  postSearchHistory,
  showCategoryList,
} from "@utils/apiServices/explore";
import { useCentralInfo } from "@utils/hooks/use-central-info";
import filter from "lodash/filter";
import { useRouter } from "next/router";
import React, { Fragment, useEffect, useState } from "react";
import { useIntl } from "react-intl";

const SEARCH_LIMIT_WORDS = scm.search.limit_words;
const GUESTVIEW = scm.explore.guest_view;

const pageNum = 0;
const pageSize = 16;

const ExploreGuestView = (props) => {
  const intl = useIntl();
  const router = useRouter();
  const baseContext = useAppContext();
  const {
    loginStatus,
    header: { category: taxonomy },
  } = baseContext;
  const { token, userLanguage } = loginStatus || {};

  const { show_bundle_courses: showBundleCourse = false } = scm.explore;
  const [cardBundleData, setCardBundleData] = useState([]);
  const [trendingData, setTrendingData] = useState([]);
  const [trendingLoading, setTrendingLoading] = useState(true);
  const [newReleaseData, setNewReleaseData] = useState([]);
  const [newReleaseLoading, setNewReleaseLoading] = useState(true);
  const [weeklyRankingData, setWeeklyRankingData] = useState([]);
  const [weeklyRankingLoading, setWeeklyRankingLoading] = useState(true);

  const getBundleCourse = async () => {
    try {
      if (showBundleCourse) {
        const { data, error } = await getCampaignList({
          pageNum,
          pageSize,
        });
        if (error) {
          throw error;
        }

        setCardBundleData([...data]);
      }
    } catch (error) {
      console.error("getBundleCourse", error);
    }
  };
  const getTrendingCourse = async () => {
    try {
      const { data, error } = await showCategoryList({
        categoryId: "",
        sortKey: "view_count",
        pageNum,
        pageSize,
        language: userLanguage,
      });
      if (error) {
        throw error;
      }
      setTrendingData([...data]);
      setTrendingLoading(false);
    } catch (error) {
      console.error("getTrendingCourse", error);
    }
  };
  const getNewReleaseCourse = async () => {
    try {
      const { data, error } = await showCategoryList({
        categoryId: "",
        sortKey: "publish_time",
        pageNum,
        pageSize,
        language: userLanguage,
      });
      if (error) {
        throw error;
      }
      setNewReleaseData([...data]);
      setNewReleaseLoading(false);
    } catch (error) {
      console.error("newReleaseRes", error);
    }
  };
  const getWeeklyRankingListRes = async () => {
    try {
      const { data, error } = await showCategoryList({
        categoryId: "",
        sortKey: "weekly_ranking",
        pageNum,
        pageSize,
        language: userLanguage,
      });

      if (error) {
        throw error;
      }
      setWeeklyRankingData([...data]);
      setWeeklyRankingLoading(false);
    } catch (error) {
      console.error("getWeeklyRankingListRes", error);
    }
  };

  useEffect(() => {
    if (appRootPath === "/" || token) {
      getTrendingCourse();
      getBundleCourse();
      getNewReleaseCourse();
      getWeeklyRankingListRes();
    }
  }, [token, userLanguage]);
  const { showPriceWeb: showPrice } = useCentralInfo({
    isPublic: true,
    keys: ["showPriceWeb"],
  });
  const handleSearch = async (searchValue) => {
    courseHandleSearch(router, searchValue);
  };
  return (
    <Fragment>
      <main className={S.explore}>
        <div className={S.searchWrapper}>
          <SearchInput
            autoComplete={true}
            suggestion={true}
            searchHistory={true}
            handleSearch={handleSearch}
            AutoCompleteList={AutoCompleteList}
            SuggestionList={SuggestionList}
            deleteSearchHistory={deleteSearchHistory}
            postSearchHistory={postSearchHistory}
            getAutoCompleteList={getAutoCompleteList}
            getSearchHistory={getSearchHistory}
            getTrendingList={getTrendingList}
            maxInputLength={SEARCH_LIMIT_WORDS}
          />
        </div>
        <Banner />
        <div className={S.academyLayout}>
          <div className={S.academyLayoutInner}>
            {taxonomy.categoryDict && (
              <div
                className={S.categoriesWrapper}
                key={taxonomy.updateTaxonomy}>
                <Categories
                  categoryDict={filter(
                    taxonomy.categoryDict,
                    (item) => item.depth === 0,
                  )}
                />
              </div>
            )}

            {showBundleCourse && cardBundleData.length ? (
              <ExploreSection
                cardData={cardBundleData}
                loading={trendingLoading}
                title={intl.formatMessage({
                  id: `explore.subsidy`,
                })}
                param={`subsidy`}
                isCampaign={true}
                showPrice={showPrice}
                testId="exploreBundle"
              />
            ) : null}

            <ExploreSection
              cardData={trendingData}
              title={intl.formatMessage({
                id: `explore.trending`,
              })}
              param={`trending`}
              loading={trendingLoading}
              showPrice={showPrice}
              testId="exploreTrending"
            />
            {weeklyRankingData.length ? (
              <ExploreSection
                cardData={weeklyRankingData}
                title={intl.formatMessage({
                  id: `explore.bestOfTheWeek`,
                })}
                param={`bestOfTheWeek`}
                loading={weeklyRankingLoading}
                showPrice={showPrice}
                testId="exploreWeekly"
              />
            ) : null}

            <ExploreSection
              cardData={newReleaseData}
              title={intl.formatMessage({
                id: `explore.newReleases`,
              })}
              param={`newReleases`}
              loading={newReleaseLoading}
              showPrice={showPrice}
              testId="exploreNewRelease"
            />
            <SettingLanguageTip />
          </div>
        </div>
      </main>
      <Footer {...props} />
    </Fragment>
  );
};

const Academy = (props) => {
  const baseContext = useAppContext();
  const academyContext = {
    ...baseContext,
  };
  const { loginStatus } = baseContext;
  const renderExploreView = () => {
    if (GUESTVIEW || loginStatus?.token) {
      return <ExploreGuestView />;
    }
    return <ExploreCustomLogin />;
  };
  return (
    <Provider value={academyContext}>
      <Header {...props} />
      {renderExploreView()}
    </Provider>
  );
};

export default Academy;
export const getServerSideProps = async (ctx) => {
  const pageData = await pageAccess(ctx, "explore");
  return generatePageProps({ pageData });
};

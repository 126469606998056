import Button from "@material-ui/core/Button";
import S from "@pages/explore.module.scss";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";
import React from "react";
import { useIntl } from "react-intl";

import type { LoadingProps } from "./types";

const Loading: FunctionComponent<LoadingProps> = ({
  title,
  param,
  loading,
  cardData = [],
}) => {
  const router = useRouter();
  const intl = useIntl();

  const directToViewMore = () => {
    router.push(`/more/${param}`);
  };
  if (loading === true || cardData.length !== 0) {
    return (
      <div className={S.secTitle}>
        <h3>{title}</h3>
        <Button classes={{ root: S.viewAllCard }} onClick={directToViewMore}>
          {intl.formatMessage({
            id: "common.button.viewAll",
            defaultMessage: "view all",
          })}
        </Button>
      </div>
    );
  }
  return null;
};

export default Loading;

import Button from "@material-ui/core/Button";
import React from "react";

import * as S from "./ContainedButton.module.scss";

const ContainedButton = ({
  children,
  containedType = "white",
  containedLabel = "blue",
  ...props
}) => (
  <Button
    variant="contained"
    disableElevation
    classes={{
      root: S.button,
      contained: S[`mui-btn-contained-${containedType}`],
      label: S[`mui-btn-contained-label-${containedLabel}`],
    }}
    {...props}>
    {children}
  </Button>
);

export default ContainedButton;

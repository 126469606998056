import ContainedButton from "@components/ContainedButton";
import context from "@components/context";
import { getUserConfig } from "@utils/apiServices/user";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import * as S from "./SettingLanguageTip.module.scss";

const SettingLanguageTip = () => {
  const intl = useIntl();
  const { loginStatus } = useContext(context);
  const [isProfileLangEditable, setIsProfileLangEditable] = useState(true);
  const [showLangTip, setShowLangTip] = useState(
    localStorage?.getItem("langTip") === "true",
  );
  const router = useRouter();
  const onGoSetting = () => {
    localStorage.setItem("langTip", false);
    setShowLangTip("false");
    return router.push("/myAccount");
  };
  const onDismiss = () => {
    localStorage.setItem("langTip", false);
    setShowLangTip("false");
  };

  useEffect(() => {
    if (loginStatus.token && localStorage.getItem("langTip") === null) {
      localStorage.setItem("langTip", true);
      setShowLangTip(true);
    }
  }, [loginStatus.token]);

  useEffect(() => {
    if (loginStatus.token) {
      const checkProfileLangIsShow = async () => {
        try {
          const { data } = await getUserConfig();
          if (data) {
            const configMap = data.reduce((prevValue, item) => {
              return {
                ...prevValue,
                [item.fieldName]: {
                  visibility: item.visibility,
                  requirement: item.requirement,
                },
              };
            }, {});
            if (!configMap.language.visibility) {
              setIsProfileLangEditable(false);
            }
          }
        } catch (error) {
          console.error("getUserConfig", error);
          setIsProfileLangEditable(false);
        }
      };
      checkProfileLangIsShow();
    }
  }, []);

  if (showLangTip === "false" || !loginStatus.token || !isProfileLangEditable)
    return null;
  return (
    <div className={S.wrapper}>
      <div>
        <Image
          width={220}
          height={164.3}
          src="/images/academyBackgroundImg.png"
          alt="academyBackgroundImg"
        />
      </div>
      <div>
        <div className={S.content}>
          <div className={S.title}>
            {intl.formatMessage({
              id: "explore.settingLanguageTip.title",
              defaultMessage: "Can't find the course in your native language?",
            })}
          </div>
          <div className={S.subTitle}>
            {intl.formatMessage({
              id: "explore.settingLanguageTip.subTitle",
              defaultMessage:
                "Try to change your language settings in the Account Information.",
            })}
          </div>
          <div className={S.actions}>
            <ContainedButton
              containedType="blue"
              containedLabel="white"
              onClick={onGoSetting}
              className={S.actionButton}>
              {intl.formatMessage({
                id: "explore.settingLanguageTip.goSetting",
                defaultMessage: "GO SETTING",
              })}
            </ContainedButton>
            <ContainedButton
              containedType="gray"
              containedLabel="blue"
              onClick={onDismiss}
              className={S.actionButton}>
              {intl.formatMessage({
                id: "explore.settingLanguageTip.dismiss",
                defaultMessage: "DISMISS",
              })}
            </ContainedButton>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SettingLanguageTip;

import clientAPI from "@utils/axios/client-api";
import { getRepositionQuery } from "@utils/object-utils";
import qs from "querystringify";

export const getAdvData = () =>
  clientAPI.get("/api/v1/adv/slider").then((res) => res.data);

export const putSliderSetting = ({ autoPlay, interval }) => {
  const query = qs.stringify({
    autoPlay,
    interval,
  });
  return clientAPI
    .put(`/api/v1/adv/slider_setting?${query}`)
    .then((res) => res.data);
};

const getConsoleBannerList = () => {
  const query = qs.stringify({
    pageNum: 0,
    pageSize: 24,
  });

  return clientAPI
    .get(`/api/v1/adv/console/banner?${query}`)
    .then((response) => response.data);
};

const repositionBannerList = (postData) => {
  const { bannerId, predecessorBannerId } = postData;

  const query = getRepositionQuery({
    dragId: bannerId,
    predecessorDragId: predecessorBannerId,
    dragIdName: "bannerId",
    predecessorDragIdName: "predecessorBannerId",
  });

  return clientAPI
    .put(`/api/v1/adv/console/banner/reposition?${query}`)
    .then((response) => response.data);
};

const changeBannerStatus = ({ bannerId, status }) => {
  const postData = {
    status,
  };

  return clientAPI
    .put(`/api/v1/adv/console/banner/${bannerId}`, postData)
    .then((response) => response.data);
};

const getPublishedBanner = () =>
  clientAPI.get("/api/v1/adv/banner").then((response) => response.data);

const deleteBanner = (bannerId) =>
  clientAPI
    .delete(`/api/v1/adv/console/banner/${bannerId}`)
    .then((response) => response.data);

export {
  changeBannerStatus,
  deleteBanner,
  getConsoleBannerList,
  getPublishedBanner,
  repositionBannerList,
};

import Loading from "@components/ExploreSection/Loading";
import { CardSkeleton } from "@components/Skeleton";
import { Icon } from "@material-ui/core";
import Card from "@page-components/Card";
import S from "@pages/explore.module.scss";
import clsx from "clsx";
import { splitEvery } from "ramda";
import type { FunctionComponent } from "react";
import React, { useEffect, useRef, useState } from "react";
import ItemsCarousel from "react-items-carousel";

import type { ExploreSectionProps } from "./types";

const ExploreSection: FunctionComponent = ({
  cardData,
  title,
  param,
  loading,
  isCampaign = false,
  showPrice = false,
  testId,
}: ExploreSectionProps) => {
  const carouselRef = useRef<HTMLHeadingElement>(null);
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);
  const [exploreStatus, setExploreStatus] = useState<string>("prepare");

  useEffect(() => {
    if (carouselRef.current) {
      setExploreStatus("ready");
    }
  }, [carouselRef, cardData]);

  const cardsData = splitEvery(1, [...cardData]);

  const renderCards = (status: string) => {
    if (status === "ready") {
      return cardsData.map((item, index) => {
        return (
          <div
            className={S.carouselCard}
            key={`cardsData${index}`}
            test-id={`cardWrapperInner_${item[0].id}`}>
            <Card
              cardData={item}
              isCampaign={isCampaign}
              showPrice={showPrice}
            />
          </div>
        );
      });
    }
    return [];
  };

  const arrowIcon = (icon: string) => {
    let arrow;

    switch (icon) {
      case "left":
        arrow = (
          <div
            className={clsx({
              [S.iconBox]: true,
              [S.left]: true,
            })}>
            <Icon>keyboard_arrow_left</Icon>
          </div>
        );
        break;
      case "right":
        arrow = (
          <div
            className={clsx({
              [S.iconBox]: true,
              [S.right]: true,
            })}>
            <Icon>keyboard_arrow_right</Icon>
          </div>
        );
        break;
      default:
        break;
    }
    if (cardData.length > 4) {
      return arrow;
    }
    return null;
  };

  const loadingProps = { title, param, loading, cardData };

  return (
    <section className={S.cardWrapper} test-id={testId}>
      <Loading {...loadingProps} />
      {cardData.length ? (
        <ItemsCarousel
          ref={carouselRef}
          classes={{
            itemWrapper: S.itemWrapper,
          }}
          infiniteLoop
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={4}
          leftChevron={arrowIcon("left")}
          rightChevron={arrowIcon("right")}
          outsideChevron
          chevronWidth={40}
          gutter={6}>
          {renderCards(exploreStatus)}
        </ItemsCarousel>
      ) : (
        <div className={S.cardWrapperInner}>
          <CardSkeleton />
        </div>
      )}
    </section>
  );
};

export default ExploreSection;

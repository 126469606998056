import Skeleton from "@material-ui/lab/Skeleton";
import * as S from "@styles/components/_skeleton.module.scss";
import React, { Fragment } from "react";

export const CategorySkeleton = () => {
  return (
    <div className={`${S.skeleton} ${S.category}`}>
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
    </div>
  );
};

export const CardSkeleton = ({ count = 4 } = {}) => {
  return (
    <Fragment>
      {new Array(count).fill(0).map((e, index) => {
        return (
          <div
            className={`${S.skeleton} ${S.cardSkeleton}`}
            key={`skeleton-card-${index}`}>
            <Skeleton variant="rect" className={S.img} />
            <div className={S.contentInner}>
              <Skeleton variant="text" className={S.title} />
              <Skeleton variant="text" className={S.subTitle} />
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};

export const RowSkeleton = ({ rows = 10 }) => {
  const arr = new Array(rows).fill(1);

  return (
    <div>
      {arr.map((item, index) => (
        <Skeleton
          key={`row-skeleton-${index}`}
          variant="rect"
          className={S.rowSkeleton}
        />
      ))}
    </div>
  );
};

export const HorizontalCardSkeleton = () => {
  const arr = new Array(8).fill(0);
  return arr.map((e, index) => {
    return (
      <div className={S.HorizontalSkeleton} key={`skeleton-card-${index}`}>
        <div className={S.horizontalSkeletonInner}>
          <Skeleton variant="rect" className={S.img} />
          <div className={S.contentInner}>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" className={S.subTitle} />
          </div>
        </div>
      </div>
    );
  });
};

import sortBy from "lodash/sortBy";
import qs from "querystring";
import { filter, uniq } from "ramda";

export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; i += 1) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const sortBySerialNumber = (data) =>
  sortBy(data, (item) => item.serialNumber);

export const getPredecessorIndex = (source, destination) => {
  if (source.index > destination.index) {
    return destination.index - 1;
  }
  return destination.index;
};

export const getRepositionQuery = ({
  dragId,
  predecessorDragId,
  dragIdName,
  predecessorDragIdName,
}) => {
  if (predecessorDragId) {
    return qs.stringify({
      [dragIdName]: dragId,
      [predecessorDragIdName]: predecessorDragId,
    });
  }
  return qs.stringify({ [dragIdName]: dragId });
};

export const filterAnswerObject = ({ postData }) => {
  const dropRepeatPostData = postData;
  dropRepeatPostData.answerSheet = filter(
    (item) => item.answers.length !== 0,
    uniq(postData.answerSheet),
  );
  return dropRepeatPostData;
};

export const getGroupInfoList = (groupInfo) => {
  let groupName = [];
  let groupId = [];
  groupInfo.forEach((item) => {
    groupId = [...groupId, item.groupId];
    groupName = [...groupName, item.groupName];
  });
  return {
    groupId,
    groupName,
  };
};

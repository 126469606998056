import { useAppContext } from "@components/context";
import Button from "@material-ui/core/Button";
import Image from "next/image";
import React from "react";
import { useIntl } from "react-intl";

import S from "./ExploreCustomLogin.module.scss";

const ExploreCustomLogin = () => {
  const intl = useIntl();
  const { loginRef } = useAppContext();
  const loginClickHandler = () => {
    if (loginRef?.current) {
      loginRef.current.open();
    }
  };

  return (
    <div className={S.wrapper}>
      <div className={S.container}>
        <Image
          src="/images/customLogin.png"
          alt="customLogin"
          width={414}
          height={312}
          priority
        />
      </div>
      <Button variant="contained" color="primary" onClick={loginClickHandler}>
        {intl.formatMessage({
          id: "login.button.login",
          defaultMessage: "Login",
        })}
      </Button>
      <div className={S.tipOne}>
        {intl.formatMessage({
          id: "login.text.tipOne",
        })}
      </div>
      <div className={S.tipTwo}>
        {intl.formatMessage({
          id: "login.text.tipTwo1",
        })}
        <br />
        {intl.formatMessage({
          id: "login.text.tipTwo2",
        })}
      </div>
    </div>
  );
};

export default ExploreCustomLogin;

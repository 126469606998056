import context from "@components/context";
import { Icon } from "@material-ui/core";
import { iconBox, left, right } from "@pages/explore.module.scss";
import { categoryI18nInfo } from "@utils/category-utils";
import Image from "next/image";
import { useRouter } from "next/router";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ItemsCarousel from "react-items-carousel";

import {
  cardWrapperInner,
  categories,
  categoriesImg,
  categoriesInner,
  text,
} from "./Categories.module.scss";

const arrowIcon = (icon) => {
  let arrow;
  switch (icon) {
    case "left": {
      arrow = (
        <div className={`${iconBox} ${left}`} test-id="carouselArrow">
          <Icon>keyboard_arrow_left</Icon>
        </div>
      );
      break;
    }
    case "right":
    default: {
      arrow = (
        <div className={`${iconBox} ${right}`} test-id="carouselArrow">
          <Icon>keyboard_arrow_right</Icon>
        </div>
      );
      break;
    }
  }
  return arrow;
};

const Categories = ({ categoryDict }) => {
  const carouselRef = useRef();
  const router = useRouter();
  const baseContext = useContext(context);
  const { language } = baseContext;
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [exploreStatus, setExploreStatus] = useState("prepare");
  useEffect(() => {
    if (carouselRef.current) {
      setExploreStatus("ready");
    }
  }, [carouselRef, categoryDict]);
  const renderArray = [...categoryDict];
  const directToCategory = (slug) => {
    router.push(`/category${slug}`);
  };
  const renderCategories = () => {
    const categoryArray = renderArray.map((category, index) => {
      const { name } = categoryI18nInfo(category, language);
      const { slug } = category;
      const coverUrl = category.coverImg?.standard?.smallUrl || "";
      return (
        <div
          className={cardWrapperInner}
          test-id="categoryFlag"
          key={`category${index}`}>
          <a
            onClick={() => directToCategory(slug)}
            className={categories}
            key={`categoryItem${index}`}
            test-id={`categoryItem${index}`}>
            <div className={categoriesInner}>
              <div className={categoriesImg}>
                {coverUrl ? (
                  <Image
                    src={coverUrl}
                    className="object-cover"
                    alt="category_cover"
                    sizes="(max-width: 1350px) 50vw, 100vw"
                    priority
                    fill
                  />
                ) : null}
                <div className={text}>{name}</div>
              </div>
            </div>
          </a>
        </div>
      );
    });

    return (
      <ItemsCarousel
        ref={carouselRef}
        infiniteLoop
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        leftChevron={arrowIcon("left")}
        rightChevron={arrowIcon("right")}
        numberOfCards={5}
        gutter={20}
        slidesToScroll={1}
        outsideChevron
        chevronWidth={40}>
        {exploreStatus === "ready" ? categoryArray : []}
      </ItemsCarousel>
    );
  };

  return <Fragment>{renderCategories()}</Fragment>;
};
export default Categories;
